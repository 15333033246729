import React, { useState } from 'react';
import './Topbar.scss';

import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';

import RoutePaths from '../../RoutePaths';
import { useAppDispatch } from '../../../app/hook';
import { setLanguage, Languages } from '../../../app/slice/Language';

import { Helmet } from 'react-helmet-async';

export default function Topbar() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const localLanguage = localStorage.getItem('language');
  const [currentLanguage, setCurrentLanguage] = useState(localLanguage ?? "en");

  function LanguageChanged(languageString: string)
  {
    setCurrentLanguage(languageString);
    dispatch(setLanguage(Languages[languageString as keyof typeof Languages]));
  }

  return (
      <div className="Topbar flex-row justify-between">
        <div className="flex-row justify-left">
            <button type="button" className="square-h" onClick={() => navigate(RoutePaths.Home)}>
              <Icon icon="oi:home" />
            </button>
        </div>
        <div className="flex-row justify-center">
          <button type="button" className="square-h" onClick={() => navigate(RoutePaths.Blue)}>
            <Icon icon="oi:aperture" />
          </button>
          <button type="button" className="square-h" onClick={() => navigate(RoutePaths.Void)}>
            <Icon icon="oi:heart" />
          </button>
        </div>
        <div className="flex-row justify-right">
          <select onChange={(selectElement) => LanguageChanged((selectElement.target.value))} value={currentLanguage}>
            <Helmet>
              <html lang={currentLanguage} />
            </Helmet>
            <option value="en">en</option>
            <option value="fr">fr</option>
          </select>
        </div>
      </div>
    );
  }
  