import React from 'react';
import './Blue.scss';

import { Outlet } from "react-router-dom";

export default function Blue() {

  return (
    <div className="Blue flex-column">
      <Outlet />
    </div>
  );
}
