import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import './App.scss';

import RoutePaths from './Features/RoutePaths';
import { initLanguage } from './app/slice/Language';
import { useAppDispatch } from './app/hook';
import { initToken } from './app/slice/Token';

import Layout from './Features/Layouts/Layout';
import Page404 from './Pages/Page404';
import HomePage from './Pages/HomePage';
import Blue from './Pages/Blue';
import BlueHome from './Pages/Blue/Home';
import Void from './Pages/Void';
import VoidHome from './Pages/Void/Home';

import { Helmet } from 'react-helmet-async';

function App() {
  const dispatch = useAppDispatch();
  dispatch(initToken());
  dispatch(initLanguage());

  return (
    <div className="App">
      <ToastContainer />
      <Helmet>
        <title>BlueVoid</title>
        <meta name='description' content='BlueVoid website' />
      </Helmet>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Navigate to={RoutePaths.Home} replace />} />
          <Route path="Home" element={<HomePage />} />

          <Route path="Blue" element={<Blue />}>
            <Route index element={<BlueHome />} />
          </Route>
          
          <Route path="Void" element={<Void />}>
            <Route index element={<VoidHome />} />
          </Route>

          <Route path="*" element={<Page404 />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
