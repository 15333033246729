import { createSlice } from '@reduxjs/toolkit';

interface TokenState {
  value: string
}

const initialState: TokenState = {
  value: '',
};

export const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    setToken: (state, { payload }: { payload:string }) => {
      localStorage.setItem('token', payload);
      return {
        ...state,
        value: payload,
      };
    },
    initToken: (state) => {
      if (!state.value) {
        const temp = localStorage.getItem('token');
        if (temp) {
          return {
            ...state,
            value: temp,
          };
        }
        return {
          ...state,
          value: '',
        };
      }
      return {
        ...state,
        value: state.value,
      };
    },
  },
});

export const { setToken, initToken } = tokenSlice.actions;

const tokenSliceReducer = tokenSlice.reducer;

export default tokenSliceReducer;
