import React from 'react';

export default function Page404() {
  const strToShow = "404 This page doesn't exist!";
  return (
    <div>
      <label htmlFor="story">
        {strToShow}
      </label>
    </div>
  );
}
