import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './app/store';
import './i18n';

import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";

import {HelmetProvider} from "react-helmet-async";

const firebaseConfig = {
  apiKey: "AIzaSyCx_VSxAhCYQ7fFZTdkOxiaAR1VeyGGAHo",
  authDomain: "bluevoid-website-33d50.firebaseapp.com",
  projectId: "bluevoid-website-33d50",
  storageBucket: "bluevoid-website-33d50.appspot.com",
  messagingSenderId: "1072268082169",
  appId: "1:1072268082169:web:b020ef112be60a3343db28",
  measurementId: "G-0W428PY6NP"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
        <BrowserRouter>
          <HelmetProvider>
            <App />
          </HelmetProvider>
       </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
