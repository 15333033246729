import React from 'react';
import { Translation } from 'react-i18next';

export default function HomePage() {
  return (
    <div className="HomePage">
      <Translation>
        {(t) => <span>{t('Home Page')}</span>}
      </Translation>
    </div>
  );
}
