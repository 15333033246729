import { configureStore } from '@reduxjs/toolkit';
import languageSliceReducer from './slice/Language';
import tokenSliceReducer from './slice/Token';

const store = configureStore({
  reducer: {
    language: languageSliceReducer,
    token: tokenSliceReducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
