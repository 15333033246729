import React, { useState } from 'react';
import './Botbar.scss';

import { useNavigate } from 'react-router-dom';
import RoutePaths from '../../RoutePaths';

export default function Botbar() {
    const navigate = useNavigate();

  return (
      <div className="Botbar flex-row justify-between">
        <div className="flex-row justify-left">
            <button type="button" className="square-h" onClick={() => navigate(RoutePaths.About)}>
            About
            </button>
            <button type="button" className="square-h" onClick={() => navigate(RoutePaths.Contact)}>
            Contact
            </button>
            <button type="button" className="square-h" onClick={() => navigate(RoutePaths.Team)}>
            Team
            </button>
        </div>
        <div className="flex-row justify-center">
        </div>
        <div className="flex-row justify-right">
        </div>
      </div>
    );
  }
  