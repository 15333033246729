import React from 'react';
import './Home.scss';

import { Helmet } from 'react-helmet-async';

export default function VoidHome() {
  return (
    <div className="Void-Home flex-column">
      <Helmet>
        <title>BlueVoid - Void</title>
        <meta name='description' content='Void part of BlueVoid website' />
      </Helmet>
      Welcome to the Void part of BlueVoid!
    </div>
  );
}
