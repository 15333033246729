import React from 'react';
import './Home.scss';

import { Helmet } from 'react-helmet-async';
import Iframe from 'react-iframe'

export default function BlueHome() {
  return (
    <div className="Blue-Home flex-column">
      <Helmet>
        <title>BlueVoid - Blue</title>
        <meta name='description' content='Blue part of BlueVoid website' />
      </Helmet>
      <span>
        Welcome to the Blue part of BlueVoid!
      </span>
      <span>
        I am planing on making a few games and programs, you can vote here for what you would prefer to see first
      </span>
      <span>
        The form will be updated every now and then
      </span>
      <span>
        (you can change your vote later, the login through google is to not spam it, plz don't abuse it, ty)
      </span>
      <Iframe url="https://docs.google.com/forms/d/e/1FAIpQLSeWXccOi-YtH2aw4-SMF-ZQFxNafZ4QQ6vbpzMBL-eD72txoA/viewform?embedded=true" className="Iframe"/>
    </div>
  );
}
