enum RoutePaths {
  Root = '/',
  Home = '/Home',
  Blue = '/Blue',
  Void = '/Void',
  About = '/About',
  Contact = '/Contact',
  Team = '/Team',
}

export default RoutePaths;
