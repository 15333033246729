import { createSlice } from '@reduxjs/toolkit';
import { log } from 'loglevel';

import i18n from '../../i18n';

export enum Languages {
  en = 'en',
  fr  = 'fr',
}

interface LanguageState {
  value: Languages | null
}

const initialState: LanguageState = {
  value: null,
};

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, { payload }: { payload: Languages }) => {
      log(state.value);
      localStorage.setItem('language', payload);
      i18n.changeLanguage(payload ?? "");
      return {
        ...state,
        value: payload,
      };
    },
    initLanguage: (state) => {
      if (state != null && state.value != null) {
        return {
          ...state,
          value: state.value,
        };
      }
      console.log(state)

      var localLanguage = localStorage.getItem('language');
      if (localLanguage == null)
      {
        localLanguage = "en"
        localStorage.setItem('language', localLanguage);
      }
      i18n.changeLanguage(localLanguage);
      return {
        ...state,
        value: localLanguage as Languages,
      };
    },
  },
});

export const { setLanguage, initLanguage } = languageSlice.actions;

const languageSliceReducer = languageSlice.reducer;

export default languageSliceReducer;
