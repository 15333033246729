import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { error } from 'loglevel';
import translationEN from './Translations/en.json';
import translationFR from './Translations/fr.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: translationEN },
      fr: { translation: translationFR },
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  }).catch((err) => { error(err); });

export default i18n;
