import React from 'react';
import './Void.scss';

import { Outlet } from "react-router-dom";

export default function Blue() {

  return (
    <div className="Void flex-column">
      <Outlet />
    </div>
  );
}
