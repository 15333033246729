import React from 'react';
import './Layout.scss';

import { Outlet } from "react-router-dom";

import Topbar from './Layout/Topbar';
import Botbar from './Layout/Botbar';

export default function Layout() {

  return (
    <div className="Layout flex-column">
      <Topbar />
      <div className="flex-row">
        <div className="Outlet">
          <Outlet />
        </div>
      </div>
      <Botbar />
    </div>
  );
}
